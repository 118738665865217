import React from 'react';
import constants from '../../constants';

const News = ({ bookingCall }) => {
    return (
        <>
            <section className="news">
                <div className="container">
                    <div className="news__content">
                        <p>
                            <span>
                                <span className="color-yellow">Hey! &nbsp;</span>
                                We're accepting the first batch of projects for the Q1 2025.
                            </span>
                            <a rel="noopener noreferrer" href={constants.site.bookingCall} className="button button--bordered">
                                Get a FREE quote today!
                                <span style={{ marginLeft: '5px' }} className="arrow"><span className="fa fa-arrow-right"></span></span>
                            </a>
                        </p>

                    </div>
                </div>
            </section>

        </>
    );
};

export default News;
