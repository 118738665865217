import React from "react";
import { Link } from 'react-router-dom';
import Logos from '../../components/Logos/Logos';
import Rating from '../../components/Rating/Rating';
import ProjectsList from '../../components/Projectfeed/Projectfeed';
import Slider from '../../components/Slider/Slider';
import Brands from '../../components/Brands/Brands';
import Testimonials from '../../components/Testimonials/Testimonials';
import PricingWeb from '../../components/Pricing/PricingWeb';

import { DollarSign, Unlock, Star, Zap, Smartphone, Search, Edit, ArrowRightLeft, Users } from 'lucide-react'

import Faqs from '../../components/Faqs/Faqs';
import SEO from '../../components/SEO/SEO';
import News from '../../components/News/News';
import constants from '../../constants.json';


const Home = () => {
    return (
        <>
            <SEO
                title="Top-tier websites for brands and entrepreneurs – VPixel CO"
                description="We design and build marketing websites to help grow and strengthen brands, entrepreneurs, and businesses worldwide."
                ogImage="https://static.vpixel.co/OG-v2-Final-EN.jpg"
                favicon="https://static.vpixel.co/favicon-32x32.png"
                appleFavicon="https://static.vpixel.co/apple-touch-icon.png"
            />


            <div style={{ backgroundImage: 'url(/images/hero-background-2.svg?v=1.1)' }} className="hero hero-home nostatic">
                <div className="container">
                    <div className="title flex-column d-flex">
                        <Rating />
                        <h1 className="h-title title-gradient">
                            Professional websites that convert visitors <span className="color-yellow">into loyal customers <img src="/images/people/mark.webp" className="img" alt="" width="70" /></span>
                        </h1>
                        <p className="lead">
                            We design and develop high-converting marketing websites that help grow brands, entrepreneurs, and businesses worldwide
                        </p>
                        <div className="actions">
                            <a href={constants.site.bookingCall} target="_blank" aria-label="Book a Call Now" className="button">
                                <img src="/images/1665701539301.jpeg" alt="Erick" width="50" />
                                Let's talk about your next project <span className="arrow">
                                    <span className="fa fa-arrow-right"></span>
                                </span></a>
                        </div>
                        <p className="small">
                            <div>
                                <DollarSign className="w-4 h-4" /> Fixed costs.
                            </div>
                            <div>
                                <Unlock className="w-4 h-4 ml-2" /> No lock-ins.
                            </div>
                            <div>
                                <Star className="w-4 h-4 ml-2" /> Great results.
                            </div>
                        </p>
                    </div>
                </div>
            </div>

            <div className="logos-section">
                <div className="container">
                    <Logos />
                </div>
            </div>

            <News bookingCall={constants.site.quoteLink} />

            <section id='about' className="about-us section nostatic">
                <div className="container">
                    <div className="row">
                        <div className="col-12 ">
                            <div className="title">
                                <span className="subtitle">We are VPixel CO</span>
                                <h2 className="title-gradient">It's nice to <span className="color-yellow">meet you!</span></h2>
                            </div>
                        </div>
                        <div className="col-12">
                            <p className='about-us__description'>
                                We are a group of talented <span>designers <img src="/images/people/girl.webp" alt="" width="29" /></span>,
                                <span>developers <img src="/images/people/erick.webp" alt="" width="35" /></span>, and
                                <span> marketing strategists <img src="/images/people/mark.webp" alt="" width="40" /></span>
                                working remotely from Barranquilla and Bogotá, Colombia 🇨🇴 who shares a passion for creating beautiful, functional, and effective websites.
                            </p>
                            <p>Taking a thoughtful, relaxed approach to our work, focusing on quality over speed. Rather than chasing fast growth or constant expansion, we choose to work on meaningful projects at our own pace, delivering exceptional digital experiences for businesses and agencies alike.</p>
                        </div>
                    </div>

                </div>
            </section>

            <section className="info">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="title">
                                <h2 className="title-gradient">Oh yes – we know what you're <span className="color-yellow">going through</span></h2>
                                <p className="lead">Your website or app is not driving <span className="color-yellow">enough clients and revenue to your business, right? <img src="/images/people/mad.webp" alt="" width="32" /></span>, and you know that THIS is the real challenge, however, you could also being facing:</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="grid grid-3">
                                <div className="grid-item d-flex flex-column align-items-center">
                                    <DollarSign className="w-12 h-12 text-yellow-500" />
                                    <h3>Website pricing <span className='color-yellow'>out of reach</span></h3>
                                    <p>You're looking for a nice and professional website but prices from $4000 up to $10.000+ are not an option for you.</p>
                                </div>
                                <div className="grid-item d-flex flex-column align-items-center">
                                    <Users className="w-12 h-12 text-yellow-500" />
                                    <h3>Leads not <span className='color-yellow'>interacting</span></h3>
                                    <p>Having difficulty converting website visitors into leads? Your strategies may be failing to resonate with your audience.</p>
                                </div>
                                <div className="grid-item d-flex flex-column align-items-center">
                                    <ArrowRightLeft className="w-12 h-12 text-yellow-500" />
                                    <h3>Conversion <span className='color-yellow'>challenges</span></h3>
                                    <p>Acquiring clicks is only the beginning. The true test is in transforming those clicks into loyal customers.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="fix nostatic">
                <div className="container">
                    <div className="fix-container">
                        <div className="info">
                            <div className="title">
                                <h2 className="title-gradient">But hey!<span className="color-yellow">We can fix this for you easily <img id='girl-2' src="/images/people/girl-2.webp" alt="" width="40" /> ⚡️</span></h2>
                                <p className="lead">
                                    Here in VPixel CO we mix our talent, conversion strategies, and technology to create amazing digital assets like Landing Pages, Marketing Websites, Sales and Marketing Funnels and more that help you nurture your business.
                                </p>
                                <p className="lead">
                                    These digital solutions act as tireless, round-the-clock sales representatives, transforming casual visitors into loyal customers. <strong className="color-yellow">Here's how VPixel CO makes this happen:</strong>
                                </p>
                            </div>
                        </div>
                        <div className="cards">
                            <Slider />
                        </div>
                        <div className="cta">
                            <img src="/images/1665701539301.jpeg" alt="Erick" width="50" />
                            <div style={{ gap: '10px' }} className="d-flex flex-column align-items-start">

                                <span>Get a FREE quote for your next website and <span className="color-yellow d-block">grow your business</span></span>

                                <span className="text">
                                    Share your project details with us and receive a comprehensive proposal including timeline, pricing, and a detailed execution plan tailored to your website needs.
                                </span>

                                <Link to={constants.site.bookingCall} target="_blank" aria-label="Start your project here!" className="button">Start your project here! <span className="arrow">
                                    <span className="fa fa-arrow-right"></span>
                                </span></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id='portfolio' className="projects">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="title">
                                <div>
                                    {/* <span className="subtitle">Looking for some results? We got them!</span> */}
                                    <h2 className="title-gradient">Successfully delivered 194+ websites for <span className="color-yellow">clients worldwide</span></h2>
                                </div>
                                <p className='lead'>
                                    Websites designed and developed with conversion, sales and marketing in mind so our customers can grow their business better, faster and easier.
                                </p>
                            </div>
                            <ul className="grid gap-2 py-4">
                                <li className="flex items-center">
                                    <Zap className="w-5 h-5" />
                                    Optimized for conversions
                                </li>
                                <li className="flex items-center">
                                    <Smartphone className="w-5 h-5" />
                                    Mobile-first responsive design
                                </li>
                                <li className="flex items-center">
                                    <Search className="w-5 h-5" />
                                    SEO-friendly architecture
                                </li>
                                <li className="flex items-center">
                                    <Edit className="w-5 h-5" />
                                    Easy to edit and manage
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="projects__grid">
                                <div className="text">
                                    <div className="info d-flex flex-column align-items-start">
                                        <Rating />
                                        <h3>Crafted with love, dedication and <span className="color-yellow">excellence</span></h3>
                                        <p>
                                            Each project is designed and crafted by our team with care and attention to detail from start to finish. Highlighting and integrating the best practices in design, development, and marketing.
                                        </p>

                                        <Link to={constants.site.bookingCall} target="_blank" aria-label="Start your project here!" className="button">Start your project here! <span className="arrow">
                                            <span className="fa fa-arrow-right"></span>
                                        </span></Link>
                                    </div>
                                </div>
                                <ProjectsList />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="tech nostatic">
                <div className="container">

                    <div className="title">
                        <div>
                            <span className="subtitle">Crafting digital experiences, pixel by pixel and line by line</span>
                            <h2 className="title-gradient">Latest technologies to create <span className="color-yellow">magical solutions</span></h2>
                        </div>
                        <p className="lead">
                            We're passionate about exploring and testing cutting-edge technologies to craft exceptional Landing Pages, Marketing Sites, eCommerce stores, and applications. Our commitment to innovation ensures we deliver the best solutions and outstanding results for our clients.
                        </p>
                    </div>

                </div>
            </section>

            <PricingWeb />

            <Testimonials />

            <section className="services">
                <div id="services" style={{ position: 'relative', top: '-150px' }}></div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="title">
                                <div>
                                    <span className="subtitle">What we do, one more time... just in case you missed it </span>
                                    <h2 className="title-gradient">We craft amazing and top-tier websites for growing companies <span className="color-yellow">all around the world</span></h2>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="services__content d-flex flex-column">
                                <div className="side">
                                    {['Landing Pages', 'Marketing Websites', 'Corporate Websites', 'E-Commerce Stores', 'Branding & Logo Design', 'Multi-language Websites', 'UI/UX Design', 'Sales and Marketing Funnels', 'Frontend Development'].map((item, index) => (
                                        <span key={index} className="item nostatic">{item}</span>
                                    ))}
                                </div>

                                <div className="middle">
                                    <h2 className="title-gradient">Within several <span className='color-yellow'>markets, niches and industries</span></h2>
                                </div>

                                <div className="side">
                                    {['SaaS', 'Startups', 'Entrepreneurs', 'Health Care', 'Real Estate', 'B2B Companies', 'Transport & Logistics', 'Legal', 'Non-Profit Organizations', 'Government', 'Marketing Agencies'].map((item, index) => (
                                        <span key={index} className="item nostatic">{item}</span>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Faqs />

            <section id='contact' className="call">
                <div className="container">
                    <div className="row">
                        <div className="col-12 d-flex flex-column">
                            <Rating />
                            <div className="title">
                                <span className="subtitle">Convinced? GOOD! Let's take the next step</span>
                                <h2 className="title-gradient">Join the 89+ companies that have <span className="color-yellow">grown their business</span> with us</h2>
                                <p className="lead">
                                    Take the first step towards transforming your business with a personalized strategy session, where we'll discuss your goals, challenges, and how we can help you achieve your digital transformation objectives.
                                </p>
                            </div>
                            <div className="actions">
                                <Link to={constants.site.bookingCall} target="_blank" aria-label="Book a exploring call here!" className="button">Book a exploring call here! <span className="arrow">
                                    <span className="fa fa-arrow-right"></span>
                                </span></Link>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Home;
