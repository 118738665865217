import React from 'react';
import constants from '../../constants.json';

const Footer = () => {
    return (

        <footer className="footer">
            <div className="container">

                <hr />

                <div className="row">
                    <div className="col-12">
                        <div className="footer__container">
                            <div className="logo">
                                <img src="/images/logo-vpx-white.svg" alt="VPixel CO" className="img-fluid navbar-brand-img" />
                                <p>
                                    We're a crack team of remote designers and developers, crafting websites, landing pages, and apps that launch brands and fuel growth.
                                    <span className="d-block small">&copy; {new Date().getFullYear()}. All rights reserved. VPixel CO S.A.S</span>
                                    <span className="d-block small">Delivering amazing web &amp; software solutions from Colombia 🇨🇴 to all over the Alpha Cuadrant 🖖.</span>
                                </p>
                            </div>

                            <div className="sm-tos">
                                <ul className="list-unstyled sm">
                                    <li>
                                        {/*
                                        <Link to={'/start'} className="button button--bordered">
                                            Get Started
                                        </Link>
                                        */}
                                        <a target='_blank' className='button button--bordered' href={constants.site.bookingCall}>Start your project here!</a>
                                    </li>
                                    <li>
                                        <a title="Follow us at Instagram" href="https://instagram.com/vpixelco" target="_blank" rel="noopener noreferrer">
                                            <span className="fa-brands fa-instagram"></span>
                                        </a>
                                    </li>
                                    <li>
                                        <a title="Follow us at Linkedin" href="mailto:e@vpixel.co" target="_blank" rel="noopener noreferrer">
                                            <span className="fa fa-paper-plane"></span>
                                        </a>
                                    </li>
                                    <li>
                                        <a title="Send us an Email" href="https://www.linkedin.com/company/vpxco" target="_blank" rel="noopener noreferrer">
                                            <span className="fa-brands fa-linkedin"></span>
                                        </a>
                                    </li>
                                </ul>
                                <div className="tos">
                                    <a href="/terms" rel="noopener noreferrer">
                                        Terms of Service
                                    </a>
                                    <a href="/privacy" rel="noopener noreferrer">
                                        Privacy Policy
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;